@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/vars.scss';

.content-with-medal-section {
  margin-top: 20px;

  &__medal {
    @include flex(row, center, center);

    svg {
      @include set-size(120px, 120px);
    }
  }

  &__suggestion {
    margin-top: 10px;
    @include font(0.8rem, 14px, $color-grey3, 400);
    max-width: 225px;
    text-align: center;
  }
}