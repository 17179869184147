@import '../../../theme/vars.scss';

.table-row {
  height: 1px; // To let content of cell have height 100%
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &--disabled {
    .table-cell {
      opacity: 0.25;
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &__loader {
    background-color: rgba($color-white, 0.25);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
