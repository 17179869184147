@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

.disclaimer {
  @include cover-screen();
  background-color: rgba($color-grey1, 0.25);
  z-index: $z-index-modal;

  &__body {
    @include position-center();
    background-color: $color-white;
    width: 70%;

    @include screen-sm() {
      width: 90%;
    }
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 1.3rem;
    top: 1.3rem;
  }

  &__header {
    box-sizing: border-box;
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
  }

  &__logo {
    
  }

  &__checkbox {
    margin: 23px;
    min-width: 20px;
  }

  &__footer {
    @include flex-row;
    box-sizing: border-box;
    padding: 1.5rem 0.5rem;
  }

  &__confirm-btn {
    margin-left: auto;
    padding: 0.7rem 2.5rem;
    border-radius: 0;
    background-color: $color-purple1;
    outline: none;
    border: none;
    @include font(1rem, $line-height-sm, $color-white, 700);

    &:disabled {
      border-color: $color-grey4;
      background-color: $color-grey4;
      color: $color-grey1;
      cursor: not-allowed;
    }
  }

  &__main {
    @include flex(row, flex-start, flex-start);
    box-sizing: border-box;
    padding: 0 1.5rem;

    @include screen-sm() {
      padding: 0 0.25rem;
    }

    & p {
      word-break: break-word;
    }
  }
}
