@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.report-table {
  background-color: $color-white;
  border-radius: 0 0 10px 10px;
  border: none;
  padding: 0 1rem;
  box-sizing: border-box; 

  .table-header {
    @include font(0.9rem, 17px, $color-grey3, 400);
    .table-header-cell {
      font-weight: 400;
    }
  }

  &__row {
    &__isClickable {
      cursor: pointer;
    }
  }

  .table-row {
    border-top: 2px solid #D9D9D9;
  }

  .table-cell {
    .report-impact-card {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}