@import '../../../theme/vars.scss';

.no-search-result {
  padding: 50px 0;
  text-align: center;

  &__title {
    font-size: 26px;
    font-weight: normal;
    color: $color-theme-black;
  }

  &__additional {
    font-size: 20px;
    color: $color-grey;
  }
}
