@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

.mobile-select {
  width: 100%;
  user-select: none;

  $options-padding-v: 4px;
  $option-line-height: 24px;
  $option-padding-v: 8px;

  &__options {
    width: 100%;
    margin: 0.25rem 0;
    background-color: transparent;
  }

  &__option {
    position: relative;
    background: none;
    padding: $option-padding-v 0;
    cursor: pointer;
    @include font(0.9rem, 17px, $color-black, 400);

    &--empty {
      opacity: 0.5;
      pointer-events: none;
    }

    $checkbox-size: 20px;
    &--checkbox {
      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 85%;
        top: ($option-line-height + $option-padding-v * 2 - $checkbox-size) * 0.5;
        width: $checkbox-size;
        height: $checkbox-size;
      }

      &:before {
        box-sizing: border-box;
        border-radius: 50%;
        border: solid 2px $color-grey5;
        transition: background-color 100ms ease-in;
      }

      &:after {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBlZENEYAgDIWJYAQiEMUG2MAI2kQbEMEIRiDCIszdOc45Uce7e3cc7NsPc65BiNiTcwvgyUBeWoDM9lYo4aloBSYGbLNQ4IiXvH7YyME95wAGZp2tCEo2MXi9LbpcBZgZ2MVdrEGdCgJxft+JaudW1f38VFBVzDsZGEhfcQedkPKa/8BCiwAAAABJRU5ErkJggg==')
          center left no-repeat;
        width: 0;
        overflow: hidden;
      }
    }

    &--selected {
      color: inherit;
      font-weight: bold;
      background: $lightest-green;
    }

    &--checkbox#{&}--selected {
      color: inherit;

      &:before {
        background: $primary-main;
      }

      &:after {
        transition: width 200ms ease-in;
        width: $checkbox-size;
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--disabled &__control {
    background: #f1f1f4;
    border: 1px solid #dcdee2;

    & > * {
      opacity: 0.5;
    }
  }

  &--disabled &__options {
    display: none;
  }
}
