@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

.search-input {
  display: inline-block;
  position: relative;
  margin-right: 16px;

  &__field {
    padding: 8px 60px 8px 10px;
    min-width: 280px;
    border: $border;
    border-radius: $input-border-radius;
    color: $color-grey-light;
    font-family: inherit;
    font-size: 18px;
    font-weight: 300;
    caret-color: $color-purple;
    width: 100%;

    &:focus-visible {
      outline: none;
      outline-offset: unset;
    }

    &:focus::placeholder {
      visibility: hidden;
    }

    &::placeholder {
      color: $color-grey-light;
    }

    &.unchangeable {
      caret-color: transparent;
    }
  }

  &__min-required {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: 12px;
    color: #b14989;
  }

  &__icon {
    position: absolute;
    right: 13px;
    top: 9px;
    cursor: pointer;

    svg {
      max-width: 20px;
    }

    &.close {
      right: 40px;
      top: 14px;

      svg {
        height: 12px;
      }

      svg path {
        fill: $color-grey-light;
      }
    }
  }
}
