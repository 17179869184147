@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/vars.scss';

.report-impact-card {
  @include flex(row, center, center);
  padding: 6px 14px;
  gap: 7px;
  border-radius: 4px;
  white-space: nowrap;

  &__waterType {
    background-color: rgba(29, 161, 242, 0.2);
    color: $color-blue;
  }

  &__energyType {
    background-color: rgba(255, 204, 51, 0.2);
    color: #f5b800;
  }

  &__wasteType {
    background-color: rgba(209, 145, 60, 0.2);
    color: #f74e31;
  }

  &__emissionType {
    background-color: rgba(86, 171, 47, 0.2);
    color: #56ab2f;
  }

  &__inclusionType {
    background-color: rgba(255, 204, 51, 0.2);
    color: $color-purple6;
  }

  &__communityType {
    background-color: rgba(29, 161, 242, 0.2);
    color: $color-pale-blue;
  }
}
