@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

.mobile-nav-screen {
  @include set-size(100vw, 92vh);
  position: fixed;
  top: 8vh;
  left: 0;
  background-color: $color-white;
  z-index: $z-index-modal;

  &__body {
    height: fill-available;
    padding: 2rem;
    @include flex(column, flex-start, center);
    gap: 30px;
  }

  &__navbar {
    @include flex(column, flex-start, center);
    gap: 24px;

    &__navlink {
      color: $color-black;
      text-decoration: none;
    }
  }

  &__footer {
    margin-top: auto;
    @include flex(column, flex-start, center);
    width: 100%;

    &__fullname {
      @include font(1rem, $line-height-sm, $color-black, 700);
    }

    &__email {
      @include font(0.9rem, 17px, $color-grey5, 400);
      margin: 5px 0;
    }

    &__options {
      @include flex(column);
      gap: 20px;
      padding: 1.5rem;
    }

    &__button {
      @include font(1.2rem, $line-height, $color-red1);
      border-color: $color-red1;
      background-color: $color-white;
      padding: 0.25rem 0.75rem;
    }
  }

  &__option {
    @include flex(row, start, center);
    @include font(1.2rem, 17px, $color-black, 500);
    cursor: pointer;
    text-decoration: none;

    a:visited {
      color: $color-black;
    }

    &__name {
      margin-left: 10px;
    }

    &--red &__name {
      color: $color-red1;
    }
  }
}
