@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

.select {
  width: 100%;
  position: relative;
  user-select: none;

  &:hover &__control {
    // border-color: $color-purple;
  }

  &__control {
    @include flex(row, flex-start, center);
    width: 100%;
    max-height: 150px;
    overflow: auto;
    padding: 0.25rem;
    border: 1px solid #ECECEC;
    border-radius: 4px;
    @include font(0.9rem, $line-height-sm, _, 400);

    &--open {
      border-color: $color-purple;
    }
    &--error {
      border-color: $color-red1;
    }
  }

  &__value-container {
    flex: 1 1 100%;
    white-space: nowrap;
  }

  &__clear,
  &__indicator {
    flex: 0 0 auto;
    line-height: 40px;
    padding: 0 0.25rem 0 0.25rem;

    svg {
      display: inline-block;
      vertical-align: middle;
      * {
        fill: $color-grey-light;
      }
    }
  }

  &__clear {
    cursor: pointer;
    margin-right: 0.25rem;
  }

  &__placeholder {
    opacity: 0.5;
    font-weight: 400;
  }

  &__value {

  }

  &__multi-value {
    display: inline-flex;
    margin: 0.25rem;
    padding: 0.25rem 0.75rem;
    color: $color-purple;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    background: #f6f8fa;
    border: 1px solid #f1f1f4;
    border-radius: 22px;
    align-items: center;

    &__label {
      display: inline-block;
      color: $color-purple;
    }

    &__remove {
      display: inline-block;
      margin-left: 0.5rem;
      cursor: pointer;

      & > svg {
        width: 10px;
        vertical-align: middle;
        & * {
          fill: $color-purple;
        }
      }
      &:hover {
        background: none;
        cursor: pointer;
      }
    }
  }

  $options-padding-v: 4px;
  $option-line-height: 24px;
  $option-padding-v: 8px;

  &__options {
    $option-height: $option-line-height + $option-padding-v * 2; // line-height + paddings

    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 110;
    margin: 0.25rem 0;
    padding: $options-padding-v 0;
    overflow: auto;
    max-height: $option-height * 5.5 + $options-padding-v; // 5.5 lines + padding
    background-color: #fff;
    border: 1px solid #dcdee2;
    border-radius: $border-radius;
  }

  &__option {
    position: relative;
    background: none;
    padding: $option-padding-v 0.75rem;
    cursor: pointer;

    &:hover {
      background: $lightest-green;
    }

    &--empty {
      opacity: 0.5;
      pointer-events: none;
    }

    $checkbox-size: 20px;
    &--checkbox {
      padding-left: 12px + $checkbox-size + 12px;

      &:before,
      &:after {
        content: '';
        position: absolute;
        left: 12px;
        top: ($option-line-height + $option-padding-v * 2 - $checkbox-size) * 0.5;
        width: $checkbox-size;
        height: $checkbox-size;
      }

      &:before {
        box-sizing: border-box;
        border-radius: 4px;
        border: solid 2px $black;
        transition: background-color 100ms ease-in;
      }

      &:after {
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBlZENEYAgDIWJYAQiEMUG2MAI2kQbEMEIRiDCIszdOc45Uce7e3cc7NsPc65BiNiTcwvgyUBeWoDM9lYo4aloBSYGbLNQ4IiXvH7YyME95wAGZp2tCEo2MXi9LbpcBZgZ2MVdrEGdCgJxft+JaudW1f38VFBVzDsZGEhfcQedkPKa/8BCiwAAAABJRU5ErkJggg==')
          center left no-repeat;
        width: 0;
        left: 12px + 3px;
        overflow: hidden;
      }
    }

    &--selected {
      color: inherit;
      font-weight: bold;
      background: $lightest-green;
    }

    &--checkbox#{&}--selected {
      color: inherit;

      &:before {
        background: $black;
      }

      &:after {
        transition: width 200ms ease-in;
        width: $checkbox-size;
      }
    }
  }

  &--disabled {
    pointer-events: none;
  }

  &--disabled &__control {
    background: #f1f1f4;
    border: 1px solid #dcdee2;

    & > * {
      opacity: 0.5;
    }
  }

  &--disabled &__options {
    display: none;
  }

  // temp styles until UI kit dropdown is implemented
  .load-more-btn {
    height: 32px;
    padding: 8px 16px;
    color: $black;
    border: none;
    border-radius: $border-radius;
    margin: 10px;
    width: 95%;
  }
}
