@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

.header {
  @include flex(row, flex-start, center);
  min-height: 10vh;
  background-color: $primary-main;

  &__right-side {
    margin-left: auto;
    margin-right: 20px;
    @include flex(row, flex-start, center);
    gap: 40px;
  }

  &__mobile {
    justify-content: space-between;
  }

  &__active-tab-name {
    @include font(1.2rem, $line-height, $color-white, 700);
  }
}

.company-logo {
  margin-left: 5%;
}

.user-avatar {
  border-radius: 50%;
  background-color: $white;
  position: relative;

  &__mobile {
    margin-right: 10px;
  }

  &__icon {

  }

  &__user-dropdown {
    position: absolute;
    top: 60px;
    right: 0px;
    z-index: 2;
    padding: 1.5rem;
    border-radius: 10px;
    background-color: $color-white;
  }
}

.navbar {
  margin-left: 80px;
  width: 30%;
  @include flex(row, flex-start, center);
  gap: 30px;
}

.navlink {
  color: $white;
  text-decoration: none;

  &--active {
    font-weight: bold;
  }
}

.logout {
  border-radius: 10%;
  cursor: pointer;

  svg {
    path {
      fill: $white;
    }
  }
}