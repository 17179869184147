@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.impact-progress-bars {
  background-color: $color-white;
  padding: 24px 40px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  &__header {
    @include font(1.5rem, 29px, $color-grey3, 500);
    margin-bottom: 20px;

    @include screen-lg() {
      margin-bottom: 10px;
      @include font(1rem, $line-height-sm, $color-grey3, 400);
    }
  }

  &__container {
    @include flex(row, space-between, stretch);
    width: 100%;
    gap: 40px;
    margin-top: 40px;

    @include screen-lg() {
      @include flex(column, space-between, stretch);
      gap: 10px;
    }
  }
}