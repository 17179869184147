@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.impact-label-wrap {
  @include flex(row, start, flex-start);
  gap: 7px;

  .impact-label {
    width: 20px;
    height: 20px;

    &__isWater {
      background: $color-light-blue;
    }

    &__isEnergy {
      background: $color-yellow1;
    }

    &__isWaste {
      background: $color-red2;
    }

    &__isEmissions {
      background: $color-green1;
    }

    &__isInclusion {
      background: $color-purple6;
    }

    &__isCommunity {
      background: $color-pale-blue;
    }
  }
}
