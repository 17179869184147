@import './toastify.scss';
@import './font.scss';
@import './vars.scss';

body {
  font-size: $font-size; // define 1rem size
  line-height: $line-height;
  color: $color-theme-black;
  margin: 0;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.link {
  color: $color-purple;
  cursor: pointer;
  text-decoration: none;

  &:visited {
    color: $color-purple;
  }
}

h1,
h2 {
  margin: 0;
  padding: 0;
}

h1 {
  margin-bottom: 24px;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.5rem;
}
