@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.impact-page-section {
  @include flex(column);
  gap: 1rem;

  &__title {
    @include font(2rem, 39px, $color-black, 700);
  }

  &__description {
    @include font(1rem, $line-height-sm, $color-black, 400);
  }
}