@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.view-activities-widget {
  border-radius: 5px;
  overflow: hidden;
  background-color: $color-white;
  position: relative;
  height: 275px;

  svg {
    margin-top: 15px;
    margin-bottom: 10px;
    @include set-size(40px, 40px);
  }

  &__image {
    height: 30%;
    background-size: cover; /* or background-size: 100% */
    background-position: center;

    &__waterType {
      background-image: url('../../../../assets/images/activities-widget/Water.jpg');
    }

    &__energyType {
      background-image: url('../../../../assets/images/activities-widget/Electricity.jpg');
    }

    &__wasteType {
      background-image: url('../../../../assets/images/activities-widget/Waste.jpg');
    }

    &__emissionType {
      background-image: url('../../../../assets/images/activities-widget/Emissions.jpg');
    }

    &__inclusionType {
      background-image: url('../../../../assets/images/activities-widget/Inclusion.webp');
    }

    &__communityType {
      background-image: url('../../../../assets/images/activities-widget/Community.jpeg');
    }
  }

  &__line {
    height: 10%;
  }

  &__background {
    height: 60%;

    &__waterType {
      background-color: $color-light-blue;
    }

    &__energyType {
      background-color: $color-yellow1;
    }

    &__wasteType {
      background-color: $color-red2;
    }

    &__emissionType {
      background-color: $color-green1;
    }

    &__inclusionType {
      background-color: $color-purple6;
    }

    &__communityType {
      background-color: $color-pale-blue;
    }
  }

  &__overflow {
    position: absolute;
    top: 0;
    left: 0;
    @include set-size(100%, 100%);
    @include flex(column, center, center);
    gap: 30px;
  }

  &__icon-container {
    background-color: $color-white;
    border-radius: 50%;
    @include set-size(110px, 110px);
    @include flex(column, center, center);
    margin-top: 30px;

    svg {
      @include set-size(80px, 80px);
    }
  }

  &__button {
    @include font(0.9rem, $line-height-sm, $color-black, 700);
    background-color: $color-white;
    color: #201223;
    padding: 0.8rem 2rem;
    white-space: nowrap;
    border-radius: 20px;

    &__waterType {
      color: $color-light-blue;
    }

    &__energyType {
      color: $color-yellow1;
    }

    &__wasteType {
      color: $color-red2;
    }

    &__emissionType {
      color: $color-green1;
    }

    &__inclusionType {
      color: $color-purple6;
    }

    &__communityType {
      color: $color-pale-blue;
    }
  }
}
