@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

.empty-state {
  @include flex(column, center, center);
  width: 300px;
  margin: 0 auto;

  &__icon {
    svg {
      @include set-size(56px, 56px);

      path {
        fill: $color-purple1;
      }
    }

    margin-bottom: 24px;
  }

  &__title {
    @include font($font-size, 24px, null, 600);
    text-align: center;
    margin-bottom: 12px;
  }

  &__text {
    @include font($font-size-medium, 24px);
    margin-bottom: 24px;
    text-align: center;
  }
}
