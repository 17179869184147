@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.report-header {
  background-color: $white;
  border-radius: 10px 10px 0 0;
  padding: 2rem 2rem 0 2rem;
  @include flex($direction: row, $items: center);
  
  h1 {
    margin-bottom: 0;
    white-space: nowrap;
  }

  &__download-report-btn {
    background-color: $color-white;
    border-radius: 5px;
    border: 1px solid $color-green2;
    @include font(1.2rem, $line-height, $color-green2, 500);
    margin-left: auto;
  }

  &__search-input {
    margin-left: 20px;
  }

  &__export-link {
    text-decoration: none;
    pointer-events: none;
  }

  &__back-btn {
    white-space: nowrap;
    background-color: $white;
    color: $color-purple1;
    border: none;
    padding: 1rem;
    padding-left: 0;
    @include flex($direction: row, $items: center);

    svg {
      margin-right: 5px;
      path {
        fill: $color-purple1;
      }
    }
  }

  &__select {
      width: fit-content;
    .select__control {
      padding: 8px 10px;
    }
  }
}
