@import '../../../../theme/mixins.scss';

.dashboard {
  display: flex;
  gap: 10px;
  flex-direction: column;

  &__top-charts {
    @include flex(row);
    gap: 10px;

    @include screen-lg() {
      flex-direction: column;
    }
  }
}