@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.activity-card {
  min-height: 310px;
  @include flex($direction: column);
  margin-bottom: 10px;
  padding: 2rem 2rem 2rem 1.5rem;
  cursor: pointer;
  transition: height 0.3s ease-in-out;
  box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.20);
  border-radius: 10px;

  &--expanded {
    height: unset;
  }
}

.activity-card-header {
  @include flex($direction: row, $items: baseline);
  gap: 8px;
}

.activity-card-header-item {
  @include flex($direction: row, $items: baseline);
  @include font(0.8rem, $line-height-sm, $color-grey, 400);
  gap: 4px;

  &__value {
    @include flex($direction: row, $items: baseline);
  }
}

.activity-card-positiveAction {
  @include font(1rem, $line-height, $color-purple1, 700);
  margin: 10px 0;
  height: 2.5rem;
}

.activity-card-description {
  @include font(0.8rem, $line-height-sm, $color-black, 400);
  white-space: normal;
  width: auto;
  min-height: 3.8rem;
  max-height: 3.8rem;
  overflow: hidden;
  text-overflow: unset;

  &--show-more {
    max-height: unset;
  }

  @include screen-lg() {
    max-height: 2.5rem;
    white-space: break-spaces;

    &--show-more {
      max-height: unset;
    }
  }
}

.activity-card-see-more {
  margin: 10px 0;
  @include font(0.8rem, $line-height-sm, $color-purple1, 700);
}

.activity-card-footer {
  margin-top: auto;
}

.activity-card-impacts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  margin: 8px 0;

  @include screen-md() {
    grid-template-columns: repeat(2, 1fr);
  }
}

.activity-card-bottom {
  @include flex($direction: row, $items: center);

}

.activity-card-location {
  @include flex($direction: row);
  gap: 5px;
  @include font(1.2rem, $line-height, $color-grey3, 600);

  svg {
    path {
      stroke: $color-grey3;
    }
  }
}

.activity-card-commit-button {
  background-color: $color-white;
  padding: 0.25rem 0.75rem;
  position: relative;
  margin-left: auto;

  &--purple {
    @include font(0.8rem, $line-height);
    border-radius: 30px;
    padding: 0.5rem 1.5rem;
    background-color: $color-purple1;
    display: flex;
    gap: 20px;

    svg {
      path {
        stroke: $color-white;
      }
    }
  }

  &--red {
    @include font(1.2rem, $line-height, $color-red1);
    border-color: $color-red1;

    &:focus {
      outline: 2px solid $color-grey4;
    }
  }

}