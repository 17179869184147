@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

.administration-page {
  @include flex(column);
  flex: 1 1 100%;
  gap: 20px;

  &__table-header {
    background-color: $white;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 2rem;
    @include flex($direction: row, $items: center);
    gap: 30px;
    flex-wrap: wrap;

    h1 {
      margin-bottom: 0;
      white-space: nowrap;
    }
  }

  &__tabs {
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    padding: 0.5rem 1rem 0 1rem;
    margin-bottom: 1rem;
    @include flex($direction: row, $content: space-between);

    &__navs {
      @include flex($direction: row);
      flex-wrap: nowrap;

      &__nav {
        @include font(1rem, $line-height-sm, $color-grey3, 700);
        padding: 1rem;
        cursor: pointer;
        white-space: nowrap;

        &--active {
          color: #702082;

          border-bottom: 4px solid #702082;
        }
      }
    }

    &__filters-icon {
      margin-left: auto;
      background-color: transparent;
      outline: none;
      padding: 0 0.5rem;

      svg {
        @include set-size(28px, 28px);
      }
    }
  }

  &__header {
    display: grid;
    grid-template-columns: min-content min-content;
    justify-content: space-between;

    @include screen-xxl() {
      grid-template-columns: repeat(1, 1fr);
    }

    h1 {
      margin-bottom: 0;
      white-space: nowrap;
      color: $color-purple1;

      @include screen-xl() {
        white-space: normal;
      }
    }
  }

  &__select {
    width: 150px;
  }

  &__widgets {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .impact-widget {
      &:not(:first-child) {
        border-left: 1px solid #d9d9d9;

        @include screen-lg() {
          border-left: none;
        }
      }
    }

    @include screen-lg() {
      grid-template-columns: repeat(2, 1fr);

      .impact-widget {
        align-items: center;

        &:nth-child(1) {
          border-right: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
        }

        &:nth-child(2) {
          border-bottom: 1px solid #d9d9d9;
        }

        &:nth-child(3) {
          border-right: 1px solid #d9d9d9;
        }
      }
    }
  }

  &__view-activities-widgets {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include screen-xxl() {
      grid-template-columns: repeat(2, 1fr);
    }
    @include screen-lg() {
      // grid-template-columns: repeat(2, 1fr);
      display: none;
    }
    gap: 30px;
  }

  &__reset-filters {
    background: transparent;
    color: $primary-main;
    border-color: $primary-main;
  }

  .view-my-activities-button {
    white-space: nowrap;
    background-color: $white;
    color: $color-purple1;
    border: none;
    margin-left: auto;
    font-weight: bold;
  }

  &__image {
    display: block;
    max-width: none;
    max-height: none;
    width: auto;
    height: auto;
    pointer-events: all !important;
  }
}
