@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.administration-activities {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px 20px;

  &__table-cell {
    display: flex;
    gap: 1rem;
  }

  &__table-row-hidden {
    background-color: $color-grey4;
    opacity: 0.5;
  }
}
