@import '../../../theme/vars.scss';

.table-header {
  box-shadow: inset 0 -1px 0 #e9ebef; // fix for Firefox

  &--fixed {
    position: sticky;
    background-color: $color-white;
    z-index: 100;
    top: 0;
    left: 0;
  }
}

.table-header-cell {
  font-weight: 600;
  text-align: left;
  padding: 1.5rem 1rem;
}
