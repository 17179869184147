@import '../../theme/vars.scss';
@import '../../theme/mixins.scss';

.confirmation-modal {
  @include cover-screen();
  background-color: rgba($color-grey1, 0.25);
  z-index: $z-index-modal;

  &__body {
    @include position-center();
    background-color: $color-white;
    border-radius: $border-radius;
    width: 420px;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 1.3rem;
    top: 1.3rem;
  }

  &__title {
    padding: 1.5rem 3rem 1.5rem 1.5rem;
    text-align: center;
    white-space: pre-wrap;
  }

  .vertical-center {
    padding-top: 4rem;
  }

  &__icon {
    @include flex(column, center, center);
    height: 80px;
    width: 80px;
    margin: 48px auto 0;
    background: $color-white;
    border: 1px solid #dedfe1;
    border-radius: 16px;

    svg {
      height: 42px;
      width: 42px;

      path {
        fill: $primary-main;
      }
    }
  }

  &__footer {
    @include flex(row, flex-end, center);
    padding: 1rem 1.5rem 1.5rem;

    button[type='submit'] {
      background-color: $color-white;
      border: 1px solid $color-red1;
      color: $color-red1;
    }

    button[type='button'] {
      background-color: $color-white;
      color:  $color-black;
    }
  }

  .bordered {
    border-top: 1px solid $color-grey5;
  }

  .vertical {
    @include flex(column, center, center);

    button[type='submit'] {
      order: 1;
      margin-bottom: 10px;
      margin-left: 0;
    }

    button[type='button'] {
      order: 2;
    }
  }

  .horizontal-space-between {
    justify-content: space-between;
  }

  &__footer-btn {
    & + & {
      margin-left: 1rem;
    }

    &--full-width {
      width: 100%;
    }
  }
}
