@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

$primaryBg: $color-purple1;
$primaryText: $color-white;
$tertiaryText: $primaryBg;
$bg: $color-theme-black;

.button {
  @include font(0.875rem, 1.25rem, $primaryText);
  border: 1px solid;
  border-radius: 10px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  font-family: inherit;
  font-weight: normal;
  text-align: center;

  &--fullWidth {
    width: 100%;
  }

  &--bordered {
    width: auto;
    padding: 12px 16px;
    border: $border;
    border-radius: $input-border-radius;
    background: $primaryText;
    color: $bg;

    &.active {
      border-color: $primaryBg;
      color: $primaryBg;
      pointer-events: none;
    }
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--large {
    padding: 1rem 1.5rem;
  }

  &--addon {
    border-color: transparent;
    background: none;
    color: $bg;
    transition: color 120ms ease-out;
    height: 48px;

    &:hover {
      color: $primaryBg;
    }
  }

  &--round {
    border-radius: 50%;
    height: auto;
  }

  &--medium {
    padding: 0.5rem 1rem;
  }

  &--tertiary {
    @include font($font-size-medium, 1.25rem, $tertiaryText);
    text-transform: none;
    border: none;
    text-align: center;
    background: transparent;
  }
}
