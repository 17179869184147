@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/vars.scss';

.impact-card {
  @include flex(column, center, center);
  @include font(0.8rem, 1rem, $color-white, 500);
  @include set-size(auto, 85px);
  padding: 6px 14px;
  gap: 7px;
  border-radius: 4px;
  white-space: nowrap;

  &__metrics {
    @include flex(column, center, center);
  }

  svg {
    @include set-size(40px, 40px);

    path {
      fill: $color-white;
    }
  }

  &__waterType {
    background-color: $color-light-blue;
  }

  &__energyType {
    background-color: $color-yellow1;
  }

  &__wasteType {
    background-color: $color-red2;
  }

  &__emissionType {
    background-color: $color-green1;
  }

  &__inclusionType {
    background-color: $color-purple6;
  }

  &__communityType {
    background-color: $color-pale-blue;
  }
}
