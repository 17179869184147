@import '../../theme/vars.scss';

.scroll-container {
  width: 100%;
  height: 100%;
  border: 1px solid $color-theme-grey;
  background-color: $color-white;
  max-height: unset;
  min-height: unset;
  position: relative;
  overflow: auto;
  overscroll-behavior: initial;
}
