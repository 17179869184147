@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.impact-widget {
  @include flex(column, center, flex-start);
  @include font(1.1rem, $line-height-sm, none, 500);
  padding: 6px 14px;
  gap: 7px;

  &__header {
    @include flex(row, center, center);

    label {
      @include font(1.1rem, $line-height-sm, #201223, 400);
      opacity: 0.4;
    }

    &__metrics {
      @include font(1.1rem, $line-height, #201223, 700);
      white-space: nowrap;
    }

    &__activities {
      @include font(1.1rem, $line-height-sm, #201223, 400);
      white-space: nowrap;
    }

    svg {
      margin-right: 5px;
    }
  }
}
