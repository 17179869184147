.table-sortable-cell {
  display: flex;
  
  &__button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 4px;
    color: grey;
  }
}
