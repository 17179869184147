@import '../../../theme/vars.scss';
@import '../../../theme/mixins.scss';

.container {
  display: block;
}

$tooltip-padding: 0.5rem;

.tooltip {
  visibility: hidden;
  background: $color-white;
  box-shadow: 0px 14px 22px 1px rgba(0, 0, 0, 0.3);
  text-align: left;
  border-radius: 10px;
  padding: $tooltip-padding;
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.isVisible {
  visibility: visible;
}

.fullWidth {
  width: 100%;
}
