@import '../../../../../theme/vars.scss';
@import '../../../../../theme/mixins.scss';

.content-with-list-section {
  max-width: 350px;
  @include font(0.9rem, 17px, $color-black, 500);

  ul {
    padding: 0 1rem;
  }
}