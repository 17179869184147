@import '../../../../../../theme/mixins.scss';
@import '../../../../../../theme/vars.scss';

.chart-container {
  @include flex(column);
  border: 1px solid $color-theme-grey;
  background-color: $color-white;
  padding: 2rem;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  flex: 1;
  
  &__title {
    @include font(1.5rem, 29px, $color-black, 700);
  }

  &__chart {
    height: 200px;
  }
}
