@import '../../../../theme/vars.scss';
@import '../../../../theme/mixins.scss';

.mobile-filters {
  @include set-size(100vw, 100%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  z-index: $z-index-modal;
  overflow: auto;

  &__header {
    @include flex(row, flex-start, center);
    width: 100%;
  }

  &__reset-filters {
    background: transparent;
    color: $primary-main;
    border-color: $primary-main;
    margin-left: auto;
  }

  &__close {
    margin-left: 30px;
    background: transparent;
    border: none;

    svg {
      @include set-size(24px, 24px);

      path {
        fill: $color-grey5;
      }
    }
  }

  &__body {
    padding: 1.5rem;
    @include flex(column, flex-start, center);
    margin-bottom: 2rem;
  }

  &__filters {
    width: 100%;
  }

  &__label {
    @include font(1rem, $line-height-sm, $color-black, 500);
    padding: 0.5rem 0;
  }

  &__horizontal-line {
    height: 1px;
    background: $color-grey5;
    margin: 1rem 0;
  }
}
