@import './vars.scss';

@mixin disabled {
  color: $color-black;
  opacity: $opacity;
  pointer-events: none;
}

@mixin table {
  width: 100%;
  border: $border;
  border-radius: $border-radius;
  background: $color-white;

  th {
    position: relative;
    padding: 24px;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    text-align: left;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding-right: 56px;
    }
  }

  td {
    padding: 24px;
    border-top: 1px solid $color-theme-grey;
    color: $color-theme-black;
    font-size: 18px;
    line-height: 24px;

    &:first-child {
      padding-left: 56px;
    }

    &:last-child {
      padding-right: 56px;
    }
  }

  .spinner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@mixin dots {
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin set-size($width: auto, $height: auto) {
  height: $height;
  width: $width;
}

@mixin font($font-size, $line-height, $color: null, $font-weight: null) {
  @if $color {
    color: $color;
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  font-size: $font-size;
  line-height: $line-height;
}

@mixin position-center {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

@mixin cover-screen($width: 100vw, $height: 100vh) {
  @include set-size($width, $height);
  position: fixed;
  top: 0;
  left: 0;
}

@mixin cover-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// TODO: should be removed when new layout applied to pages in GP and @table
@mixin temporary-header-layout-fix() {
  margin: -1.5rem -1.5rem 1.5rem -1.5rem;
}

@mixin modal() {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  background-color: $color-white;
  border: 1px solid $color-grey7;
  border-radius: 3px;
  text-align: center;
}

@mixin modal-background() {
  @include set-size(100%, 100%);
  background: $color-warm-pink;
  position: relative;
}

@mixin checkbox {
  display: none;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    line-height: 20px;
  }

  & + label::before {
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    padding-top: 3px;
    margin-right: 14px;
    color: $color-white;
    border: 1.5px solid $color-purple;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
  }

  &:checked + label::before {
    content: '\2713';
    background: $color-purple;
  }

  &:disabled + label::before {
    background-color: #e9ecef;
  }
}

// Flex helpers
@mixin flex($direction: row, $content: flex-start, $items: stretch) {
  display: flex;
  flex-direction: $direction;
  justify-content: $content;
  align-items: $items;
}

@mixin flex-row {
  @include flex(row);
}

@mixin flex-column {
  @include flex(column);
}

// Media helpers
@mixin media-breakpoint-up($name) {
  $value: map-get(
    $map: $breakpoints,
    $key: $name,
  );

  @media (min-width: $value) {
    @content;
  }
}
@mixin media-breakpoint-down($name) {
  $value: map-get(
    $map: $breakpoints,
    $key: $name,
  );

  @media (max-width: $value) {
    @content;
  }
}

@mixin screen-sm() {
  @include media-breakpoint-down(sm) {
    @content;
  }
}
@mixin screen-md() {
  @include media-breakpoint-down(md) {
    @content;
  }
}
@mixin screen-lg() {
  @include media-breakpoint-down(lg) {
    @content;
  }
}
@mixin screen-xl() {
  @include media-breakpoint-down(xl) {
    @content;
  }
}
@mixin screen-xxl() {
  @include media-breakpoint-down(xxl) {
    @content;
  }
}

@mixin screen-xxxl() {
  @include media-breakpoint-down(xxxl) {
    @content;
  }
}

@mixin set-scroll-styles(
  $size: $default-scroll-width,
  $thumb-color: $dark-grey,
  $track-color: $lightest-grey,
  $border-radius: 4px
) {
  -webkit-overflow-scrolling: touch;
  // styles for the firefox

  -moz-overflow-y: auto;
  scrollbar-color: $thumb-color $track-color;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: $size;
    width: $size;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
    border-radius: $border-radius;
    width: $size;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
    border-radius: $border-radius;
  }
}

@mixin set-custom-scroll(
  $size: $default-scroll-width,
  $thumb-color: $dark-grey,
  $track-color: $lightest-grey,
  $border-radius: 4px,
  $overflow-x: hidden,
  $overflow-y: auto
) {
  @include set-scroll-styles($size, $thumb-color, $track-color, $border-radius);

  overflow-x: $overflow-x !important;
  overflow-y: $overflow-y !important;
}

@mixin set-custom-x-scroll(
  $size: $default-scroll-width,
  $thumb-color: $dark-grey,
  $track-color: $lightest-grey,
  $border-radius: 4px
) {
  @include set-scroll-styles($size, $thumb-color, $track-color, $border-radius);

  overflow-x: auto !important;
}

@mixin set-custom-xy-scroll(
  $width: $default-scroll-width,
  $thumb-color: $dark-grey,
  $track-color: $lightest-grey,
  $border-radius: 4px
) {
  @include set-custom-scroll($width, $thumb-color, $track-color, $border-radius);
  @include set-custom-x-scroll($width, $thumb-color, $track-color, $border-radius);
}

@mixin custom-dashed-border($color, $dash-size) {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    border: #{$dash-size}px dashed $color;
    transition: border-color 100ms ease-in;
    top: -#{$dash-size - 1}px;
    bottom: -#{$dash-size - 1}px;
    left: -#{$dash-size - 1}px;
    right: -#{$dash-size - 1}px;
  }
}
