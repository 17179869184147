@import '../../../../../theme/vars.scss';
@import '../../../../../theme/mixins.scss';

.tooltip-content-container {
  padding: 1rem;
  background-color: $color-white;

  &__header {
    @include font(1rem, $line-height-sm, $color-black, 700);
  }
}