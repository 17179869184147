@import '../../../../../theme/vars.scss';
@import '../../../../../theme/mixins.scss';

.content-in-progress-bar {
  @include flex(row);

  &__line {
    height: auto;
    width: 1px;
    background-color: #D9D9D9;
  }
}