@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

.faq {
    width: 100%;
    height: 80vh;
    border: none;

    &__container {
        @include set-size(100%, 100%);
    }

    &__main_title {
        font-size: 32px;
        font-weight: 600;
    }

    &__fontSizeSuper {
        font-size: 42px;
    }

    &__fontColorGreen {
        color: #107c10;
    }

    &__fontSizeXxxLarge {
        font-size: 32px;
    }

    &__fontColorPurple {
        color: #5c2d91;
    }

    &__root-278 p {
        color: rgb(84, 88, 89);
    }

    &__root-278 a {
        color: rgb(112, 32, 130);
    }

    &__table &__filledHeaderTableStyleTheme {
        border-collapse: collapse;
        border-spacing: 0;
        width:100%;
    }

    &__table &__filledHeaderTableStyleTheme tr:first-child {
        background-color: #702082;
        color: white;
        font-weight: 600;
    }

    &__table &__filledHeaderTableStyleTheme td {
        border: 1px solid #702082;
        padding: 6.4px 16px 6.4px 8px;
    }
}