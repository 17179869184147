@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.user-dropdown {
  &__fullname {
    @include font(1rem, $line-height-sm, $color-black, 700);
  }

  &__email {
    @include font(0.9rem, 17px, $color-grey5, 400);
    padding: 4px 0;
  }

  &__line {
    height: 1px;
    width: auto;
    background-color: #d9d9d9;
    margin: 10px 0;
  }

  &__options {
    @include flex(column);
    gap: 10px;
  }

  &__option {
    @include flex(row);
    @include font(0.9rem, 17px, $color-black, 400);
    cursor: pointer;
    text-decoration: none;

    a:visited {
      color: $color-black;
    }

    &__name {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    &--red &__name {
      color: $color-red1;
    }
  }
}
