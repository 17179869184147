@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/vars.scss';

.impact-progress-bar {
  width: 100%;
  @include flex(column);

  .rspbprogressbar {
    background-color: rgb(169 168 168 / 60%) !important;
  }

  &__bar {
    padding: 1rem;
    order: -1;

    @include screen-lg() {
      order: 1;
      margin-top: 45px;
    }
  }

  &__metrics {
    @include font(1rem, $line-height-sm, _, 500);
    @include flex(row);
    gap: 10px;
    margin: 10px 0;
    padding-left: 10px;

    &__waterType {
      color: $color-blue;
    }

    &__energyType {
      color: #f5b800;
    }

    &__wasteType {
      color: #f74e31;
    }

    &__emissionType {
      color: #56ab2f;
    }

    &__inclusionType {
      color: $color-purple6;
    }

    &__communityType {
      color: $color-pale-blue;
    }

    &__info-icon {
      display: none;
      svg {
        @include set-size(28px, 28px);
      }

      @include screen-lg() {
        display: block;
        margin-left: auto;
      }
    }
  }

  &__type {
    @include font(1rem, $line-height-sm, #828282, 400);
    padding-left: 20px;
  }

  &__medal {
    position: absolute;
    top: -52px;

    svg {
      @include set-size(32px, 32px);
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 8px;
      // background-color: $color-blue1;
    }

    &:after {
      content: ' ';
      position: absolute;
      top: 47px;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 10px;
      background-color: $color-white;
    }

    &--waterType {
      &:before {
        background-color: $color-blue;
      }
    }

    &--energyType {
      &:before {
        background-color: #f5b800;
      }
    }

    &--wasteType {
      &:before {
        background-color: #f74e31;
      }
    }

    &--emissionType {
      &:before {
        background-color: #56ab2f;
      }
    }

    &--inclusionType {
      &:before {
        background-color: $color-purple6;
      }
    }

    &--communityType {
      &:before {
        background-color: $color-pale-blue;
      }
    }
  }
}
