@import '../../../../../../theme/vars.scss';
@import '../../../../../../theme/mixins.scss';

.ellipsis {
  background-color: $color-grey4;
  border-radius: 50%;
  @include set-size(10px, 10px);
  margin: 0 1px;

  &--green {
    background-color: $color-green2;
  }

  &--blue {
    background-color: $color-light-blue;
  }
}