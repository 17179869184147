@import '../../../../theme/mixins.scss';
@import '../../../../theme/vars.scss';

.activities-table {
  display: flex;
  justify-content: center;
  align-self: center;
  box-sizing: border-box;
  padding: 40px 20px;

  &__column {
    flex: 1 0 0;
    @include flex(column, center, center);
    margin: 0 20px;
  }

  &__body {
    @include flex(row, center, flex-start);

    @include screen-lg() {
      @include flex(column, center, center);
    }
  }
}