@import '../../../../../theme/mixins.scss';
@import '../../../../../theme/vars.scss';

.mobile-impact-progress-view {
  @include set-size(100vw, 100%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  z-index: $z-index-modal;
  overflow: auto;

  &__header {
    @include flex(row, flex-start, center);
    width: 100%;
  }

  &__close {
    margin-left: auto;
    background: transparent;
    border: none;

    svg {
      @include set-size(24px, 24px);

      path {
        fill: $color-grey5;
      }
    }
  }

  &__body {
    padding: 1.5rem;
    @include flex(column, flex-start, center);
    margin-bottom: 2rem;
  }

  &__content {
    @include flex(column, flex-start, center);

    .content-with-list-section {
      max-width: 100%;
    }
  }
}
