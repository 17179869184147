@import '../../theme/mixins.scss';
@import '../../theme/vars.scss';

$footer-height: 100px;

.main-page {
  width: 100%;
  height: fit-content;
  @include flex(column);
  background: #f7fafb;

  &__main {
    height: 100%;
    overflow: auto;
  }

  &__content {
    --main-page-margin: 1.5rem 10rem;
    flex: 1 1 100%;
    min-height: calc(100vh - ($footer-height * 1.8));
    position: relative;
    margin: var(--main-page-margin);
    background: #f7fafb;
    z-index: 1;

    @include screen-xxxl() {
      margin: 1.5rem 7rem;
    }

    @include screen-xl() {
      margin: 1.5rem 5rem;
    }

    @include screen-md() {
      margin: 1.5rem 1.5rem;
    }
  }
}

.scroll-to-top-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
  background-color: $color-white;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  opacity: 0.5;
  color: $color-black;

  &:hover {
    opacity: 1;
  }
}